div.Contact div.container {
    padding-top: 35px;
}

div.Contact div.container img {
    padding-top: 110px;
    width: 550px;
    height: auto;
}

div.Contact div.container .form {

    background-color: cornflowerblue;
    border-radius: 9px;
    border: 3px solid blue;
}

div.Contact div.container .form .content-form {
    padding-top: 40px;
}

div.Contact div.container .content-form h1,
div.Contact div.container .content-form label {
    color: aliceblue;
}

div.Contact div.container .form .content-form .form-group {
    padding-top: 7px;
}

div.Contact div.container .form .content-form button {
    color: aliceblue;
}

@media screen and (min-width: 375px) and (max-width: 390px),
(max-height: 850px) {
    div.Contact div.container {
        padding-top: 40px;
    }

    div.Contact div.container .row.logo {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    div.Contact div.container img {
        padding-top: 0px;
        width: 290px;
        height: auto;
    }

    div.Contact div.container .form {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    div.Contact div.container .form .content-form {
        padding-top: 10px;
        padding-bottom: 0px;
    }
}

@media screen and (min-width: 768px) and (max-width: 880px) and (max-height: 1180px) {

    div.Contact div.container {
        padding-top: 140px;
    }

    div.Contact div.container .row.logo {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    div.Contact div.container img {
        padding-top: 0px;
        width: 290px;
        height: auto;
    }

    div.Contact div.container .form {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    div.Contact div.container .form .content-form {
        padding-top: 10px;
        padding-bottom: 0px;
    }
}

@media screen and (min-width: 881px) and (max-width: 1280px) and (max-height: 720px) {

    div.Contact div.container {
        padding-top: 65px;
    }

    div.Contact div.container .row.logo {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    div.Contact div.container img {
        padding-top: 0px;
        width: 290px;
        height: auto;
    }

    div.Contact div.container .form {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    div.Contact div.container .form .content-form {
        padding-top: 10px;
        padding-bottom: 0px;
    }
}