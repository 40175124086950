ul {
    list-style-type: none;
}

a {
    text-decoration: none;
}

.header {
    position: fixed;
    height: 60px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    transition: .3s ease-in;
    overflow: hidden;

}

.header-bg {
    background-color: rgba(250, 250, 250, .9);
}

.header .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1240px;
    margin: auto;
    height: 100%;
    padding: 0 1rem;
}

.header .nav-menu a {
    color: #000000;
    text-decoration: none;
}

.header .navbar img {
    height: 30px;
    width: auto;
    margin-right: 35px;
    margin-left: 15px;
    margin-bottom: 15px;
}

.header .nav-menu {
    display: flex;
    margin-right: 145px;
}

.header .nav-item {
    padding: 1rem;
    font-weight: 500;
    margin-left: 45px;
}

.header .nav-item a:hover {
    padding-bottom: 12px;
    border-bottom: 3px solid var(--secondary-color);
}

.hamburger {
    display: none;
}


@media screen and (max-width:940px) {
    .header {
        max-width: 100%;
        background-color: rgba(250, 250, 250, .9);
    }

    .header .navbar {
        max-width: 100%;
    }

    .hamburger {
        display: inline;
        margin-right: 18px;
    }

    .nav-menu {
        position: fixed;
        left: -100%;
        top: 90px;
        flex-direction: column;
        background-color: rgba(250, 250, 250, .9);
        width: 100%;
        height: 90vh;
        z-index: 999;
        text-align: center;
        transition: .3s;
    }

    .nav-menu.active {
        left: 0;
    }

    .nav-item {
        margin: 1.5rem 0;
    }

    .header .navbar img {
        width: 47px;
        height: auto;
        margin-left: 5px;
        margin-top: 13px;
    }
}