.center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-justify {
    text-align: justify;
}

.text-right {
    text-align: right;
}

.text-justify {
    text-align: justify;
}

div.Welcome {
    height: 100vh;
    z-index: -1;
}

@keyframes move-welcome {
    0% {
        opacity: 0;
        left: 0px;
        top: -135px;
    }

    100% {
        opacity: 1;
        left: 0px;
        top: 0px;
    }
}

div.Welcome .container {
    position: relative;
    padding-top: 250px;
    /* animation: 
    move-welcome 4s;*/
}

div.Welcome .container img.logo {
    width: 575px;
    height: auto;
    padding-bottom: 50px;
}

div.Welcome .row.picture img.pic_welcome {
    padding-top: 220px;
    width: 300px;
    height: auto;

}

div.welcome div.promotion {
    margin-top: 180px;
    height: 500px;
}

div.Welcome div.promotion button {
    border-radius: 30px;
    margin-left: 20px;
}

div.Welcome .container .carousel-item img {
    width: 550px;
    height: auto;
    border-radius: 10px;
}

@media screen and (max-width: 550px) {
    div.Welcome .container {
        padding-top: 88px;
    }

    div.Welcome .container img.logo {
        width: 250px;
        height: auto;
        padding-bottom: 105px;
    }

    div.Welcome div.promotion button {
        border-radius: 30px;
        margin-left: 22px;
    }

}


div.Welcome .promotion {
    background-position: 100%;
    margin-top: 180px;
    height: 550px;

}

@media screen and (min-width: 375px) and (max-width: 390px),
(max-height: 850px) {

    div.Welcome .promotion {
        background-position: 150px;
        margin-top: 50px;
        height: 550px;
    }

}

@media screen and (min-width: 768px) and (max-width: 880px) and (max-height: 1180px) {
    div.Welcome .promotion {
        background-position: 450px;
        margin-top: 186px;
        height: 750px;

    }
}

@media screen and (min-width: 881px) and (max-width: 1280px)  and (max-height: 720px) {
    div.Welcome .promotion {
        background-position: 100%;
        margin-top: 0px;
        height: 550px;

    }
}